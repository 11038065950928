@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";
@import "../../lib/HooksFormFields/form-mixin";

.testimonialsList {
  display: flex;
  flex-direction: column;
  position: relative;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: $margin-md;
    @media screen and (min-width: $lg-screen) {
      padding: $margin-lg;
    }
    h1 {
      color: $primary-color;
      margin: 0px;
    }
    .create {
      display: flex;
      align-items: center;
      .search {
        position: relative;
        input {
          @include input;
          padding: 12px;
          &:focus {
            color: $primary-color;
          }
        }
        svg {
          position: absolute;
          right: 8px;
          top: 10px;
          color: $text-color;
          font-size: 26px;
        }
      }
      .btn {
        @include btn;
        text-decoration: none;
        margin-left: 15px;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0px 0px 0px 0px;
    li {
      display: block;
      width: 100%;
    }
  }
}

.loader {
  @include flex-center;
  width: 100%;
  height: 60vh;
}

.list {
  padding: 0px $margin-md;
  overflow-y: auto;
  &:last-child {
    height: calc(100vh - 166px);
  }
  @include scrollbar;
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
  @media screen and (min-width: $lg-screen) {
    padding: 0px $margin-lg;
  }
  .row {
    display: flex;
    text-decoration: none;
    margin-bottom: 15px;
    background-color: rgba($inputBg, 0.6);
    border-radius: $radius;
    width: 100%;
    @include transition;
    &.sort {
      margin-top: 20px;
      background-color: transparent;
      .col {
        padding: 0px 20px;
        color: $text-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          @include transition;
          margin-left: 5px;
          &.active {
            transform: rotate(180deg);
          }
        }
        &:first-child {
          text-transform: none;
        }
        &:last-child {
          color: $text-color;
          justify-content: flex-end;
          padding: 0px 10px;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      background-color: $inputBg;
    }
    .col {
      padding: 16px 20px;
      width: 30%;
      @include semiBold;
      &:first-child {
        width: 60%;
        text-transform: uppercase;
      }
      &:last-child {
        width: 10%;
        text-align: right;
        color:#7fc728;
      }
    }
  }

}
