@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.checkbox {
  &.inline {
    display: flex;
    align-items: center;
    .container {
      margin-left: $margin-xs;
    }
  }
  label {
    @include label;
    cursor: pointer;
    +.container {
      margin-top: 10px;
    }
  }

  .container {
    display: flex;
    padding-top: 3px;
    justify-content: flex-start;
    height: 39px;
  }

  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: solid 1px $inputBg;
    background-color: $inputBg;
    cursor: pointer;
    >svg {
      color: $primary-color;
    }
  }
}