@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.event {
  position: relative;
  padding: $margin-md;
  @media screen and (min-width: $lg-screen) {
    padding: $margin-lg;
  }
  .header {
    position: relative;
    h1 {
      color: $primary-color;
      max-width: 60%;
      margin: 0px;
    }
  }
  .submit {
    position: absolute;
    right: $margin-md;
    top: $margin-md;
    @media screen and (min-width: $lg-screen) {
      right: $margin-lg;
      top: $margin-lg;
    }
    .btn {
      @include btn;
      &:first-child {
        margin-right: 10px;
        min-width: 120px;
      }
      &.saved {
        opacity: 1;
        background-color: rgba($primary-color, 0.4);
        box-shadow: none;
        border: 1px solid transparent;
      }
      &.success {
        background-color: transparent;
        border: 1px solid transparent;
        color: #7fc728;
      }
      &.draft {
        background-color: transparent;
        border: 1px solid transparent;
        color: $text-light;
      }
    }
  }
  .form {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .field {
      margin-bottom: 20px;
    }
    .content {
      width: 60%;
    }
    .meta {
      width: calc(40% - 50px);
      .delete {
        background-color: transparent;
        @include regular;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        box-shadow: none;
        padding: 0px;
        color: rgba($white, 0.4);
        svg {
          font-size: 16px;
          margin-right: 6px;
        }
        &:hover {
          color: $warn;
          text-decoration: underline;
        }
      }
    }
  }
}
