@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.list-tags{
  width: 100%;
  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+ul{
      margin-top: $margin-sm;
    }
  }

  ul{
    display: grid;
    gap: $margin-sm;
    width: 100%;
    padding: 0;
    margin: 0;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    li{
      list-style: none;
      button {
        font-family: 'Montserrat-SemiBold', sans-serif;
        width: 100%;
        border: none;
        color: $text-color;
        background-color: transparent;
        border-radius: $radius;
        box-shadow: inset 0 0 0 1px darken($text-light, 30%);
      }
      button.selected {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $black;
        box-shadow: inset 0 0 0 1px $primary-color;
      }
    }
  }
}
