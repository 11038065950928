@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

h2 + .schedule {
  margin-top: -15px;
}

.schedule {

  .grid {
    display: grid;
    grid-template-columns: 85px 1fr 1fr; 

    >div:nth-child(2),
    >div:nth-child(3) {
      text-align: center;
      justify-content: center;
      margin-bottom: 5px;
    }

    >div:nth-child(3n - 1):not(:nth-child(2)) {
      background-color: $inputBg;
      border-right: solid 1px $bg-color;
      &:not(:nth-child(23)) {
        border-bottom: solid 1px $bg-color;
      }
      input {
        text-align: center;
      }
    }

    >div:nth-child(3n):not(:nth-child(3)) {
      background-color: $inputBg;
      &:not(:nth-child(24)) {
        border-bottom: solid 1px $bg-color;
      }
      input {
        text-align: center;
      }
    }


    >div:nth-child(5) {
      border-radius: $radius 0 0 0;
    }
    >div:nth-child(6) {
      border-radius: 0 $radius 0 0;
    }

    >div:nth-child(24) {
      border-radius: 0 0 $radius 0;
    }
    >div:nth-child(23) {
      border-radius: 0 0  0 $radius;
    }


    .containerLabel {
      display: flex;
      align-items: center;
      label {
        color: $text-color;
      }
    }
  }
}
