$bg-color: #181C1F;
$white: #FFFFFF;
$primary-color: #FA5C4F;
$secondary-color: #8AE5B9;
$success: #5BC284;
$warn: #FA5C4F;

$stat-great: #74BE73;
$stat-warn: #FFA945;
$stat-critical: #E76D6D;

$white: #FFFFFF;

$light-grey: #CDD0D5;
$grey: #9BA0AB;

$black: #222429;
$text-color: #818283;
$text-light: #9BA0AB;

$inputBg: #343434;
$inputColor:#9BA0AB;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 700px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;


$h1: 36px;
$h2: 25px; 
$h3: 22px;
$medium: 18px;
$button: 16px;
$regular: 14px;
$radius: 10px;


@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

