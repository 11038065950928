@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.layout{
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow: auto;
  background-color: $bg-color;
  .container {
    flex: 1;
    overflow-y: auto;
    @include scrollbar;
    &::-webkit-scrollbar-thumb {
      background: rgba($primary-color, 0.5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }
  }
}