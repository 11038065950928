@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-code {
  margin: 10px 0px 10px 0px;
  padding: 10px 0px;
  label {
    @include label;
  }

  &.login:not(.inline) label {
    background-color: $grey;
    color: $white;
  }

  &.inline {
    @include inline;
    align-items: flex-start;
    label {
      margin-top: 12px;
    }
  }

  &:not(.inline) {
    label + .container {
      margin-top: $margin-sm;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }


  .input {
    @include input;
    @include scrollbar;
    padding-top: 16px;
    padding-bottom: 16px;
    resize: none;

    &.disabled {
      pointer-events: none;
    }
  }

  &.primary {
    @include primary;
  }

  &.discret textarea {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: none;
  }

  &.secondary {
    @include secondary;
    textarea {
      line-height: 18px !important;
      border: none !important;
    }
  }

  &.login {
    @include login;
  }

  .container {
    width: 100%;
    .count-characters {
      text-align: right;
      font-size: $regular;
      margin: 0;
      padding: $margin-sm;
      color: $text-light;
      span {
        color: $text-color;
      }
    }
  }
}
