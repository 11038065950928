@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.blockOptions {
  background-color: $primary-color;
  border-radius: $radius;
  button {
    @include btn;
    display: block;
    width: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pages {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  h2 {
    font-size: 24px;
    color: $primary-color;
  }
  .list {
    width: 100%;
    margin-bottom: $margin-md;
    background-color: $inputBg;
    border-radius: $radius;
    .row {
      display: flex;
      text-decoration: none;
      margin-bottom: 15px;
      background-color: rgba($inputBg, 0.6);
      border-radius: $radius;
      width: 100%;
      @include transition;
      &:hover {
        background-color: $inputBg;
      }
      .col {
        width: 40%;
        padding: 15px 20px;
        font-size: 16px;
        color: $text-color;
        @include semiBold;
        &:first-child {
          text-transform: uppercase;
        }
        &:last-child {
          width: 40%;
          text-align: right;
          color:#7fc728;
        }
      }
    }
  }

  .buttonBlocks {
    .block-list {
      display: flex;
      flex-wrap: wrap;
    }
    p {
      font-size: $medium;
      margin: 0 $margin-sm 0 0;
    }
    button {
      margin-top: 8px;
      padding: 8px 12px;
      font-size: 14px;
    }
    .btn:not(:last-child) {
      margin-right: 8px;
    }
  }

  .btn {
    @include btn;
    display: block;
    float: left;
    text-decoration: none;
  }
}

.item {
  position: relative;
  // border-bottom: 1px solid rgba($white, 0.4);
  // margin-bottom: $margin-sm;
  padding: 0 $margin-sm;
  border-radius: $radius;
  h3 {
    font-size: 20px;
    margin-bottom: 24px;
    text-transform: capitalize;
  }
  .tag {
    display: block;
    margin-bottom: 30px;
  }
  .link {
    margin-bottom: 24px;
    label {
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      color: #9ba0ab;
    }
    .field {
      background-color: rgba($white, 0.1);
      padding: 0px 10px;
      border-radius: $radius;
      margin-top: 8px;
    }
  }
  .fields {
    &.col-2 {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .field {
        width: calc(50% - 10px);
      }
    }

    .media {
      padding: $margin-xs 0;
    }

  }
  .containerButtons {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
    background-color: $primary-color;
    height: 30px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    button {
      height: 30px;
      border: none;
      cursor: pointer;
      padding: 0;
    }
    .delete {
      background-color: $primary-color;
      color: $text-color;
      width: 30px;
      border-radius: 50%;
      &:hover {
        background-color: darken($primary-color, 20%);
      }
      svg {
        position: relative;
        top: 2px;
        font-size: 16px;
      }
    }
  }

  &:hover {
    background-color: darken($inputBg, 5%);
    box-shadow: inset 0px 0px 0px 1px $primary-color;
    .containerButtons {
      display: flex;
    }
  }

}