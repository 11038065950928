@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.slider {

  .grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); 
    gap: $margin-xs;
    margin-bottom: $margin-md;

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $inputBg;
      border-radius: $radius;
      // max-height: 100px;

      // container media_img
      >div {
        box-shadow: none !important;
      }
    }
  }
}
