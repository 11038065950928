@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.pages {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-size: 24px;
    color: $primary-color;
  }
  .list {
    width: 100%;
    margin-bottom: 6px;
    .row {
      display: flex;
      text-decoration: none;
      margin-bottom: 15px;
      background-color: rgba($inputBg, 0.6);
      border-radius: $radius;
      width: 100%;
      @include transition;
      height: 100%;
      &:hover {
        background-color: $inputBg;
      }
      .col {
        position: relative;
        width: 40%;
        padding: 15px 20px;
        font-size: 16px;
        color: $text-color;
        .color {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 10px;
          height: 100%;
          border-top-left-radius: $radius;
          border-bottom-left-radius: $radius;
        }
        @include semiBold;
        &:first-child {
          text-transform: uppercase;
        }
        &:last-child {
          width: 40%;
          text-align: right;
          color:#7fc728;
        }
      }
    }
  }
  .btn {
    @include btn;
    display: block;
    float: left;
    text-decoration: none;
  }
}