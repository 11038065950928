@mixin regular {
  font-family: "Montserrat-Regular", sans-serif;
}

@mixin bold {
  font-family: "Montserrat-Bold", sans-serif;
}

@mixin semiBold {
  font-family: "Montserrat-SemiBold", sans-serif;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}


@mixin btn {
  @include semiBold;
  @include transition;
  align-items: center;
  justify-content: center;
  font-size: $button;
  background-color: $primary-color;
  padding: 11px 20px;
  color: $black;
  border: solid 1px $primary-color;
  border-radius: $radius;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: none;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    top: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader > div {
      position: initial;
    }

    svg {
      position: relative;
      top: -50%;
      vertical-align: middle;
      color: $white;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 10px;
  }

  &.secondary {
    background-color: $secondary-color;
    border: solid 1px $secondary-color;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.grey {
    background-color: #BCC2C5;
    border: solid 1px #BCC2C5;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.black {
    background-color: $black;
    border: solid 1px $black;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.light {
    background-color: rgba(113, 122, 151, 0.04);
    border: solid 1px rgba(113, 122, 151, 0.04);
    color: $text-color;
  }

  &.third {
    background-color: $white;
    border: solid 1px $text-color;
    color: $text-color;
  }

  &.dashed {
    background-color: rgba(#96A5B8, 0.2);
    border: 1px dashed rgba($text-color, 0.2);
    color: $text-color;
    svg {
      color: $text-color;
    }
    >span {
      text-decoration: underline;
    }
  }


  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding: $margin-md;
  margin: 0 auto;
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin backButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  display: block;
  font-size: 40px;
  width: 25px;
  height: 45px;
  color: $primary-color;
  margin-bottom: $margin-xs;
  cursor: pointer;
  svg {
    position: relative;
    left: -8px;
    width: 40px;
    height: 40px;
  }
}

@mixin initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $margin-xs;
  border-radius: 50%;
  background-color: $primary-color;
  border: 2px solid #f0f0f0;
  background-position: center;
  background-size: cover;

  > span {
    position: relative;
    font-family: "DMSans";
    font-size: $medium;
    color: $white;
  }
}

@mixin containerField {
  margin-bottom: $margin-lg;

  &.grid2Tags ul{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &.gridNotes ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    button {
      padding-left: $margin-xs;
      padding-right: $margin-xs;
    }
  }

}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin authTemplate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  h3 {
    text-align: center;
  }
  .top {
    position: relative;
    z-index: 0;
    background-position: center;
    .container {
      @include container();
      img {
        display: block;
        width: 80px;
        margin: 0px auto 0px auto;
      }
    }
  }

  .container {
    position: relative;
    z-index: 1;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      color: $primary-color;
      width: 100%;
    }
    button {
      @include btn;
      margin-bottom: 20px;
    }
  }
}

@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
