@import '../../../../styles/variables';
@import "../../../../styles/_mixins.scss";

.emailresetpwd-form{
  max-width: 570px;

  .form{
    margin-bottom: $margin-md;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      color : $warn;
      font-size: $regular;
    }
    .message-succeed{
      @include regular;
      color : $secondary-color;
      font-size: $regular;
    }
  }

  .containerSubmit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
    }
  }

}


