@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  width: 202px;
  background-color: #121517;
  .logo {
    display: flex;
    justify-content: center;
    padding: $margin-md $margin-md 0 $margin-md;
    img {
      // height: 120px;
      width: 60px;
    }  
  }
  .navigation {
    padding: $margin-md;
    ul {
      list-style: none;
      padding: 0;
      margin: $margin-md 0;
      display: flex;
      flex-direction: column;
    }
    ul li {
      display: block;
      list-style: none;
      text-align: center;
      &:not(:last-child) {
        margin-bottom: $margin-xs;
      }
      a {
        position: relative;
        @include semiBold;
        font-size: $medium;
        text-decoration: none;
        color: $text-light;
        // &:after {
        //   content: '';
        //   display: block;
        //   position: absolute;
        //   top: 53%;
        //   left: -15px;
        //   width: 8px;
        //   height: 8px;
        //   border-radius: 4px;
        //   background-color: $text-light;
        //   transform: scale(0, 0) translate(0, -50%);
        // }

        &.disabled {
          pointer-events: none;
        }

        &.active {
          color: $primary-color;
          &:after {
            background-color: $text-color;
            transform: scale(1, 1) translate(0, -50%);
          }
        }
      }
    }
  }

  .bottom {
    margin-top: auto;
    padding: $margin-md;
    text-align: center;

    .profilePicture {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      min-width: 45px;
      height: 45px;
      min-height: 45px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin: -50px auto $margin-sm auto;
      background-color: $primary-color;

      p {
        @include semiBold;
        position: absolute;
        top: 50%;
        color: #121517;
        font-size: 18px;
        transform: translate(0, -50%);
      }

    }

    .logout {
      display: flex;
      align-items: center;
      margin-top: $margin-md;
      padding-top: $margin-md;
      border-top: solid 1px rgba($light-grey, 0.2);

      button {
        @include btn;
        background-color: transparent;
        border: 1px solid transparent;
        color: $text-light;
        outline: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin-right: $margin-xs;
        width: 45px;
        height: 45px;
        cursor: pointer;
      }
      p {
        color: $text-light;
        margin: 0;
        cursor: pointer;
      }
    }

  }

}